import React, { useEffect, lazy, Suspense, useState } from 'react'
import './App.scss'

import { Route, Routes } from 'react-router-dom'
import { AuthContext } from '@providers/AuthProvider'
import Protected from '@components/Protected'
import { PageStates } from '@utils/PageStateUtils'
import DomainNotConfigured from '@pages/DomainNotConfigured/DomainNotConfigured'

const Login = lazy(()=>import("@pages/Login/Login"))
const Logout = lazy(()=>import("@pages/Logout/Logout"))
const Catalog = lazy(()=>import("@pages/Catalog/Catalog"))
const Item = lazy(()=>import("@pages/Item/Item"))
const Orders = lazy(()=>import("@pages/Orders/Orders"))
const Order = lazy(()=>import("@pages/Order/Order"))
const Documents = lazy(()=>import("@pages/Documents/Documents"))
const AdvancedSearch = lazy(()=>import("@pages/AdvancedSearch/AdvancedSearch"))
const Checkout = lazy(()=>import("@pages/Checkout/Checkout"))
const CheckoutReturn = lazy(()=>import("@pages/Checkout/CheckoutReturn"))
const CheckoutSuccess = lazy(()=>import("@pages/Checkout/CheckoutSuccess"))

const AccessDenied = lazy(()=>import("@pages/AccessDenied/AccessDenied"))
const Maintenance = lazy(()=>import("@pages/Maintenance/Maintenance"))

function App() {

  const auth = React.useContext(AuthContext)
  const [appState, setAppState] = useState<PageStates>(PageStates.INIT)

  const init = async() =>{
      await auth.setEnvironment()
      await auth.setUserData()
      setAppState(PageStates.IDLE)
  }

  useEffect(() => {
    init()
  },[])

  if(appState === PageStates.INIT) return <></>
  if(appState === PageStates.IDLE && auth.environment?.domainData === undefined) return <DomainNotConfigured />

  return (
    <Suspense fallback={<></>}>
      <Routes>
          <Route path="/" element={<Protected><Catalog /></Protected>} />
          <Route path="/catalog" element={<Protected><Catalog /></Protected>} />
          <Route path="/catalog/advanced-search" element={<Protected><AdvancedSearch /></Protected>} />
          <Route path="/catalog/:skuParent" element={<Protected><Item /></Protected>} />
          <Route path="/orders" element={<Protected><Orders /></Protected>} />
          <Route path="/orders/:id" element={<Protected><Order /></Protected>} />
          <Route path="/documents" element={<Protected><Documents /></Protected>} />
          <Route path="/checkout" element={<Protected><Checkout /></Protected>} />
          <Route path="/checkout/return" element={<Protected><CheckoutReturn /></Protected>} />
          <Route path="/checkout/success" element={<Protected><CheckoutSuccess /></Protected>} />
          
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/accessdenied" element={<AccessDenied />} />
          <Route path="/maintenance" element={<Maintenance />} />
      </Routes>
    </Suspense>
  )
}

export default App;
