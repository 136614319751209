import Localization from '@localization/Index'
import Swal, {SweetAlertOptions} from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast, ToastContent } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import brandStyle from '../brand.module.scss'
import { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { copyToClipboard } from './StringUtils'

const askConfirmation = async(options: SweetAlertOptions) => {
    const { 
        confirmButtonText=Localization.OK, 
        cancelButtonText=Localization.ANNULLA,
        denyButtonText=Localization.RIFIUTA,
        confirmButtonColor=brandStyle.bPrimary, 
        cancelButtonColor=brandStyle.bMuted,
        denyButtonColor=brandStyle.bDanger,
        showCancelButton=true,
        showConfirmButton=true,
        showDenyButton=false
    } = options
    const swal = withReactContent(Swal)
    const confirmResponse = swal.fire({
        ...options,
        confirmButtonText,
        cancelButtonText,
        denyButtonText,
        confirmButtonColor,
        cancelButtonColor,
        denyButtonColor,
        showCancelButton,
        showConfirmButton,
        showDenyButton
    })

    return confirmResponse
}

const showWaiting = (title: string | HTMLElement) => {
    const swal = withReactContent(Swal)
    swal.fire({
        title,
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: ()=>{
            swal.showLoading()
        }
    })
    return
}

const showError = (message: string | JSX.Element, title: string | JSX.Element="", options: SweetAlertOptions={}, callback: Function|undefined=undefined) => {
    const swal = withReactContent(Swal)
    swal.fire({
        ...options,
        icon: 'error',
        title,
        html: message
    }).then(()=>{
        callback?.()
    })
}

const showWarning = (message: string | JSX.Element, title: string | JSX.Element="", options: SweetAlertOptions & { width?: string }={}, callback: Function|undefined=undefined) => {
    const swal = withReactContent(Swal)
    swal.fire({
        ...options,
        icon: 'warning',
        title,
        width: options.width,
        html: message
    }).then(()=>{
        callback?.()
    })
}

const showSuccess = (message: string | JSX.Element, title: string | JSX.Element="", options: SweetAlertOptions={}, callback: Function|undefined=undefined) => {
    const swal = withReactContent(Swal)
    swal.fire({
        ...options,
        icon: 'success',
        title,
        html: message
    }).then(()=>{
        callback?.()
    })
}

const closeWaiting = () => {
    const swal = withReactContent(Swal)
    swal.close()
    return
}

/**
 * shows a success toast
 * N.B. YOU MUST ADD A ToastContainer COMPONENT IN THE PAGE THAT CALLS THIS FUNCTION TO BE SHOW CORRECTLY!
 * @param content the content to be show in the toast
 * @returns 
 */
const toastSuccess = (content:ToastContent<unknown>) => {
    toast.success(content, {
        hideProgressBar: true,
        draggable: false,
        closeButton: false,
        autoClose: 2000,
        theme: 'colored',
        icon: <i className="bi-check font-2x text-white" />
    })
}

/**
 * shows a error toast
 * N.B. YOU MUST ADD A ToastContainer COMPONENT IN THE PAGE THAT CALLS THIS FUNCTION TO BE SHOW CORRECTLY!
 * @param content the content to be show in the toast
 * @returns 
 */
const toastError = (content:ToastContent<unknown>) => {
    toast.error(content, {
        hideProgressBar: true,
        draggable: false,
        closeButton: false,
        autoClose: 2000,
        theme: 'colored',
        icon: <i className="bi-x font-2x text-white" />
    })
}

/**
 * shows a warning toast
 * N.B. YOU MUST ADD A ToastContainer COMPONENT IN THE PAGE THAT CALLS THIS FUNCTION TO BE SHOW CORRECTLY!
 * @param content the content to be show in the toast
 * @returns 
 */
const toastWarning = (content:ToastContent<unknown>) => {
    toast.warning(content, {
        hideProgressBar: true,
        draggable: false,
        closeButton: false,
        autoClose: 2000,
        theme: 'colored',
        icon: <i className="bi-exclamation-triangle font-2x text-white" />
    })
}


const littleMessage = async(options: SweetAlertOptions) => {
    const { 
        confirmButtonText=Localization.OK, 
        confirmButtonColor=brandStyle.bPrimary, 
        cancelButtonColor=brandStyle.bMuted, 
        showCancelButton=false,
        showConfirmButton=true
    } = options
    const swal = withReactContent(Swal)
    const confirmResponse = swal.fire({
        ...options,
        iconColor: brandStyle.bPrimary,
        confirmButtonText,
        confirmButtonColor,
        cancelButtonColor,
        showCancelButton,
        showConfirmButton
    })

    return confirmResponse
}

const ErrorCustomer = ({messages}:{messages:string[]})=>{
    const [copyMessage, setCopyMessage] = useState(Localization.COPIA_VALORE)

    return <div className='d-flex flex-column align-items-center justify-content-center'>
                <h5>{Localization.ERRORE_CONTATTARE_ASSISTENZA}</h5>
                <h6 className='mt-2'>{`${Localization.DETTAGLI_ERRORE}:`}</h6>
                <OverlayTrigger placement='top' overlay={<Tooltip id="error_copy_tooltip"  style={{position: 'fixed'}}>{copyMessage}</Tooltip>}>
                    <div className='d-flex align-items-center justify-content-center fs-6 cs-link' onClick={async ()=>{
                        await copyToClipboard({text: messages.join(", ")})
                        setCopyMessage(Localization.COPIATO)
                    }}>
                        {messages.map((message, index)=>{
                            return <div key={`error_customer_details_${index}`}>{message}</div>
                        })}
                    </div>
                </OverlayTrigger>
            </div>

}
const showErrorForCustomer = (messages: string[], options: SweetAlertOptions={}, callback: Function|undefined=undefined) => {

    const swal = withReactContent(Swal)
    swal.fire({
        ...options,
        icon: 'error',
        title:"",
        html: <ErrorCustomer messages={messages} />
    }).then(()=>{
        callback?.()
    })
}

export { askConfirmation, showWaiting, showError, showWarning, showSuccess, closeWaiting, toastSuccess, toastError, toastWarning, littleMessage, showErrorForCustomer}