import { NavigateFunction } from "react-router-dom"

const en = {
    __LANGUAGE: "en",
    ACCEDI: "Sign in",
    LOGIN: "Login",
    RICORDAMI: "Remember me",
    CREDENZIALI_NON_RICONOSCIUTE: "Unrecognized credentials",
    PASSWORD_DIMENTICATA: "Forgot password?",
    CERCA: "Search",
    CAMBIA_PASSWORD: "Change password",
    SOTTOUTENTI: "Subusers",
    BILLING: "Billing",
    MODULI: "Modules",
    LOGOUT: "Logout",
    COPIATO: "Copied!",
    OK: "Ok",
    MODIFICA: "Edit",
    ELIMINA: "Delete",
    VISUALIZZA: "View",
    ORDINA: "Sort",
    AGGIUNGI: "Add",
    CARICA: "Upload",
    SCARICA: "Download",
    ANNULLA: "Cancel",
    RIFIUTA: "Deny",
    RIPRISTINA: "Restore",
    RIGENERA: "Regenerate",
    SELEZIONA_TUTTI: "Select all",
    DESELEZIONA_TUTTI: "Unselect all",
    CONFERMA: "Confirm",
    INVIA: "Send",
    ESPORTA: "Export",  
    ATTENDERE: "Please wait...",
    ATTENZIONE: "Warning",
    ERRORE_GENERICO: "An error occurred, please try again.",
    ERRORE_CONTATTARE_ASSISTENZA: "An error has occurred, please contact support for assistance.",
    DETTAGLI_ERRORE: "Error details",
    SELEZIONA_UN_VALORE: "Select a value",
    SELEZIONA_UN_FILE: "Select a file",
    SI: "Yes",
    NO: "No",
    DA: "From",
    TUTTI: "All",
    SALES_ORDER: "Sales order",
    TRANSFER_ORDER: "Transfer order",
    SALVA: "Save",
    E_MAIL: "Email",
    ELIMINAZIONE_IN_CORSO: "Deleting...",
    SALVATAGGIO_IN_CORSO: "Saving...",
    OPERAZIONE_AVVENUTA_CON_SUCCESSO: "Operation successful",
    COPIA_VALORE: "Copy value",
    COPIA_ERRORE: "Click to copy error",
    PROCEDI: "Proceed",
    PROSEGUI: "Continue",
    CAMBIA: "Change",
    CREA: "Create",
    ISTRUZIONI: "Instructions",
    DETTAGLI: "Details",
    MOSTRA: "Show",
    RISULTATI: "__COUNT__ results",
    HOME: "Home",
    INIZIA: "Start",
    VERIFICA: "Check",
    SELEZIONA_FILE: "Select file",
    SALE_CHANNEL: "Sales channel",
    SALES_CHANNEL: "Sales channels",
    BADGE_SHOP_PRIMARY: "Primary shop",
    ARTICOLO: "Item",
    QUANTITA: "Quantity",
    PREZZO_UNITARIO: "Unit price",
    PREZZO_TOTALE: "Total price",
    SCONTO: "Discount",
    MARKUP: "Markup",
    TOTALE_ORDINE: "Order total",
    SUBTOTALE: "Subtotal",
    SPESE_DI_SPEDIZIONE: "Shipping costs",
    VAI_A_DETTAGLIO: "Go to details",
    APRI_ANTEPRIMA: "Open preview",
    GOTO_CHECKOUT: "Go to checkout",
    LISTINO: "Pricebook",
    EMPTY: "Empty",
    CLOSE: "Close",
    CALENDAR:{
        LAST_3M:"Last 3 months",
        LAST_6M:"Last 6 months",
        THIS_Y: "Current year",
        LAST_Y: "Last year",
        LAST_2Y: "Last 2 years",
    },
    CONTACTS: {
        EMAIL: "Email",
        PHONE: "Phone",
        MOBILE: "Mobile"
    },
    MENU: {
        CATALOGO: "Catalog",
        ORDINI: "Orders",
        I_MIEI_DOCUMENTI: "My documents",
        ORDINE: "Order",
        PROFILO: "Profile",
        LOGOUT: "Logout",
        RICERCA_AVANZATA: "Advanced search",
        DETTAGLIO_ARTICOLO: "Item details",
        CHECKOUT: "Checkout",
        INFO: "Info",
    },
    FORMS: {
        ERRORI_NEL_FORM: "There are errors in the form fields, please check.",
        CAMPO_OBBLIGATORIO: "Required field",
        LUNGHEZZA_COMPRESA: "The field length must be between __MIN__ and __MAX__ characters.",
        VALORE_COMPRESO: "The field value must be between __MIN__ and __MAX__.",
        ERRORE_REGEX: "Invalid format",
    },
    CONFIGURAZIONE: {
        NUOVA_CONFIGURAZIONE: "New configuration"
    },
    REACT_SELECT: {
        PLACEHOLDER: "Select a value...",
        NESSUN_RISULTATO: "No results found."        
    },
    EXPORT_EXCEL: {
        ESPORTA_CATALOGO: "Catalog export",
        ESPORTA_CATALOGO_DISABLED: (max:number)=>`Catalog export (Max ${max} results)`,
        ESPORTA_CATALOGO_EXCEL_DESC: (count:number)=><>you can download in an Excel file the {count>1?<><strong>{count}</strong> items</>:<>item</>} selected.</>,
        NOME_FILE: "Choose a filename (optional, without extension)",
        NOME_FILE_NON_VALIDO: "Filename not valid",
        NOME_FILE_GIA_PRESENTE: "Filename already present",
        ELABORAZIONE_AVVIATA_CON_SUCCESSO: "Processing started successfully!",
        ELABORAZIONE_AVVIATA_CON_SUCCESSO_TEXT: (filename: string)=><>You will find your file <strong>{filename}</strong> in the <strong>My Documents</strong> section when it's ready.</>,
        ELABORAZIONE_IN_CORSO: ()=><>A catalog export is running right now. You can check the progress in the <strong>My Documents</strong> section.</>,
    },
    IMPORT_EXCEL: {
        TITLE: "Import from Excel",
        SELEZIONA_MODALITA: "Select a mode",
        AGGIUNGI_A_ESISTENTE: "Add to the items already in the cart",
        DA_CARRELLO_VUOTO: "Empty the cart and then import the items from the file",
        IMPORTA: "Import",
        IMPORT_DISCLAIMER: "It is possible that, at the time of processing, some items may no longer be available in the requested quantities. At the end of the process, a summary of the items actually imported into the cart will be displayed.",
        ANALISI_EXCEL_IN_CORSO: "Analizzo il file Excel...",
        AGGIUNTA_ARTICOLI_A_CARRELLO: "Aggiungo gli articoli al carrello...",
        SUMMARY: {
            RIEPILOGO: "Summary",
            NUMERO_ARTICOLI_TAGLIE_TOTALI: (skuparentCount: number, sizesCount: number) => <>In the file there are <strong>{skuparentCount}</strong> items for a total of <strong>{sizesCount}</strong> sizes.</>,
            NUMERO_ARTICOLI_TAGLIE_RICHIESTE: (skuparentCount: number, sizesCount: number) => <>In them, <strong>{skuparentCount}</strong> items for a total of <strong>{sizesCount}</strong> sizes have quantities requested.</>,
            NESSUN_ARTICOLO_DA_AGGIUNGERE: "There are no items available to be added to the order.",
            ARTICOLO_TAGLIA: "Item / size",
            RICHIESTI: "Requested",
            DISPONIBILI: "Available",
            DA_AGGIUNGERE: "To be Added",
            RICHIESTA_CONFERMA_AGGIUNTA: "The quantities shown in the previous table will be added to the order. Are you sure?"
        }
    },
    COMPONENTS: {
        ADDRESS_SELECTOR: {
            AGGIUNGI_NUOVO_INDIRIZZO: "Add a new address",
            MODIFICA_INDIRIZZO_ORDINE: "Edit order address",
            INDIRIZZO_DI_SPEDIZIONE: "Shipping address",
            INDIRIZZO_DI_FATTURAZIONE: "Billing address",
            SELEZIONA_INDIRIZZO: "Select an address",
            ALIAS: "Address alias",
            NOME: "First name",
            COGNOME: "Last name",
            RAGIONE_SOCIALE: "Company name",
            INDIRIZZO: "Address",
            NAZIONE: "Country",
            INDIRIZZO_1: "Address 1",
            INDIRIZZO_2: "Address 2",
            CITTA: "City",
            PROVINCIA: "Province",
            SIGLA_PROVINCIA: "Province code",
            CODICE_POSTALE: "Postal code",
            EMAIL: "Email",
            TELEFONO: "Phone",
            CELLULARE: "Mobile",
            PARTITA_IVA: "VAT number",
            CODICE_FISCALE: "Tax code",
            ERRORE_TELEFONO: "Invalid format (e.g., correct: +39123456789)",
            SALVA_NEI_TUOI_INDIRIZZI: "Save to your addresses",
            PREDEFINITO: "Default",
            ERRORE_NOMINATIVO: "Company name or first and last name must be provided",            
        },
        SHIPPING_METHOD: {
            CUSTOMER: "My own Courier",
            DHL_EXPRESS: "DHL Express",
            ERRORE_METODO_MANCANTE: "Shipping method is not defined",
            ERRORE_METODO_NON_GESTITO: (method: string) => `The shipping method ${method} is not supported by the system`,            
        },
        PAYMENT_METHOD: {
            BANK_TRANSFER: "Bank transfer",
            PAYPAL: "Paypal",
            CREDIT_CARD: "Credit card",
            ERRORE_METODO_MANCANTE: "Payment method is not defined",
            ERRORE_METODO_NON_GESTITO: (method: string) => `The payment method ${method} is not supported by the system`
        }
    },
    PAGES: {
        LOGIN: {
            ACCEDI: "Sign in",
            LOGIN: "Login",
            RICORDAMI: "Remember me",
            CREDENZIALI_NON_RICONOSCIUTE: "Invalid credentials",
            PASSWORD_DIMENTICATA: "Forgot password?",
            PASSWORD_DIMENTICATA_DESC: "Enter your email to reset your password.",
            PASSWORD_DIMENTICATA_SUCCESS: "We have sent you an email to reset your password. Follow the instructions and try logging in with the new password.",
            TORNA_ALLA_LOGIN: "Back to login",            
        },
        CATALOGO: {
            NESSUN_ARTICOLO_DISPONIBILE: "No items match the selected filters.",
            ACQUISTA: "Purchase",
            TAGLIA: "Size",
            NESSUNA: "None",
            TUTTE: "All",
            CERCA_ARTICOLO: "Search for an item",
            MINIMO_TRE_CARATTERI: "Please enter at least three characters.",
            NESSUN_RISULTATO_VAI_A_RICERCA_AVANZATA: "No results found. Go to advanced search",
            VEDI_ALTRI_RISULTATI: "Show more results",
            NESSUN_RISULTATO: "No results found.",
            RISULTATI: (count: number) => { if (count === 1) { return "Result"; } else { return "results"; } },            
            FILTERS: {
                SELEZIONA_TUTTI: "Select all",
                BRAND: "Brand",
                SEASON: "Seasons",
                SIZE: "Sizes",
                CATEGORIES: "Categories",
                IMAGES: "Images",
                NESSUN_FILTRO: "No filter",
                WITH_IMAGES: "With images",
                WITHOUT_IMAGES: "Without images",
                FTA: "Fta",
                CARRYOVER: "Carry over",
                COLORS: "Colors",
                RESETTA_FILTRI: "Reset filters",
                ORDINAMENTO: {
                    SKU_PARENT_ASC: "Item code (A-Z)",
                    SKU_PARENT_DESC: "Item code (Z-A)",
                    LAST_INFO_UPDATE_ASC: "Least recent update",
                    LAST_INFO_UPDATE_DESC: "Most recent update",
                }                
            }
        },
        DOCUMENTS: {
            NESSUN_DOCUMENTO_DISPONIBILE: "No document available.",
            NESSUN_DOCUMENTO_IN_ELABORAZIONE: "No document is being processed.",
            DOCUMENTI_DISPONIBILI: (count:number) => (count===1)?<>1 document available</>:<>{count} documents available.</>,
            DOCUMENTI_IN_ELABORAZIONE: (count:number) => (count===1)?<>1 document processing</>:<>{count} documents processing.</>,
            TIPO: "Type",
            TIPO_CATALOGO: "Catalog",
            NOME_FILE: "Filename",
            DATA: "Date",
            TEMPO_ELABORAZIONE: "Elaboration time elapsed",
            CONFERMA_ELIMINAZIONE_DOCUMENTO: (filename: string) => <>Do you confirm the deletion of the document <strong>{filename}</strong>?</>,
            ELIMINAZIONE_DOCUMENTO_SUCCESSO: (filename: string) => <>Document <strong>{filename}</strong> successfully deleted.</>,
            STAGE: {
                INIT: "Initialization",
                FETCH_DATA: "Data fetch",
                COMPOSE_EXCEL: "Excel file composition",
            },
            PASSAGGIO: "Stage",
            ELABORAZIONE_ERRORE: "The processing of the document has exited with error.",
            DESCRIZIONE_ERRORE: "Error description",
            CONFERMA_INTERRUZIONE_EXPORT: "Do you want to stop processing this document?",
        },
        CHECKOUT: {
            IL_TUO_CARRELLO_VUOTO: "Your cart is empty",
            CARRELLO_VUOTO_DESC: (navigate: NavigateFunction) => <div>Check out our <span className="cs-link" onClick={() => navigate("/catalog")}>catalog</span> and find something that suits you!</div>,
            FATTURAZIONE: "Billing",
            SPEDIZIONE: "Shipping",
            RIEPILOGO: "Summary",
            METODO_PAGAMENTO: "Payment method",
            METODO_SPEDIZIONE: "Shipping method",
            ARTICOLI_ORDINE: "List of items in the order",
            NUMERO_ARTICOLI: "Number of items",
            TOTALE_ARTICOLI: "Total items",
            IVA: "VAT",
            TOTALE_ORDINE: "Order total",
            ACQUISTA: "Buy",
            AMEX_MASSIMO_SUPERATO: (amount:number)=>`The payment limit for American Express is €${amount.toFixed(2)}. For amounts exceeding this limit, please select another payment method.`,
            ERRORE_PAGAMENTO: "An error occurred during the payment process, please try again.",
            GRAZIE_PER_ACQUISTO: "Thank you for your purchase!",
            DESC_BONIFICO: (bankTransferDescriptionCode:string)=>{
                if(bankTransferDescriptionCode === "BBZ_ONLINE_WINDOW") return <div><p>Please proceed with the order payment within two days from the order date.</p><p>For more information contact <a href="mailto:customercare@onlinewindow.com" className="cs-link">customercare@onlinewindow.com</a></p></div>
                return undefined
            },
            IDENTIFICATIVO_PAGAMENTO_DESC: (paymentId:string)=><div>Your payment code is <strong>{paymentId}</strong>.</div>,
            IDENTIFICATIVO_ORDINE_DESC: (paymentId:string)=><div>Your order number is <strong>{paymentId}</strong>.</div>,
            GRAZIE_ACQUISTO_DESC: (navigate: NavigateFunction) => <div><span className="cs-link" onClick={() => navigate("/catalog")}>Come back to the catalogue</span></div>,
            ELABORAZIONE_ORDINE_IN_CORSO: "Processing order...",
            ORDINE_NON_TROVATO: "Order not found",
            PAGAMENTO_ANNULLATO: "Payment operation canceled.",
            PROBLEMA_PAGAMENTO: "There was a problem with the payment",
            VAI_A_CARRELLO: (navigate: NavigateFunction) => <div>Click <span className="cs-link" onClick={() => navigate("/checkout")}>here</span> to return to checkout.</div>,
            PAGAMENTO_CARTA_IN_CORSO: "Credit card payment in progress, modifications to the current order are not possible.",
        },
        ORDERS: {
            ARTICOLI: "Items",
            SCARICA_ORDINI: "Download Orders",
            ORDINE: "Order",
            STATO_ORDINE: "Status",
            DATA: "Date",
            CONTROVALORE: "Total",
            NUMERO_ARTICOLI: "item number",
            APRI_ANTEPRIMA: "Show preview",
            VAI_A_DETTAGLIO: "Go to details",
            COPIA_ID: "Copy ID",
            COPIA_CODICE_ORDINE: "Copy order code",
            INFORMAZIONI_ACQUIRENTE: "Buyer info",
            SPEDIZIONE: "Shipping",
            FATTURAZIONE: "Billing",
            ORDINI_EFFETTUATI: "__COUNT__ Orders placed",
            STATUS_CHANGE: "Change order status",
            ORDER_NUMBER: "Order number",
            SPEDITO_A:"Shipping to",
            FROM_SENT_TO:"To be sent to",
            SEND_TO:"Send to",
            STATUS: {
                PENDING: "Pending",
                CONFIRMED: "Confirmed",
                CANCELED: "Canceled",
                REFURBISHED: "Refurbished",
                SHIPPED: "Shipped",
                PARTIALLY_SHIPPED: "Partially shipped",
                OTHER: "Other",
            }
        },
        ORDER:{
            CURRENT_ORDER_TITLE: "Your current order",
            ORDINE_NUMERO: "Order N°",
            DATA: "Date",
            QUANTITA_NELL_ORDINE: "Quantity in order",
            TOTALE: "Total",
            SVUOTA: "Empty",
            VAI_A_CHECKOUT: "Go to checkout",
            VARIAZIONE_TEXT: "The item in the order has undergone a price change for you. You can check the updated conditions in the list below.",
            VARIAZIONI_TEXT: "The items in the order have undergone a price change for you. You can check the updated conditions in the list below.",
            NO_ITEMS_IN_ORDER_CURRENT: (navigate: NavigateFunction) => <div>Your order is empty. Check out our <span className="cs-link" onClick={() => navigate("/catalog")}>catalog</span> and find something that suits you!</div>,
            NO_ITEMS_IN_ORDER: "The order is empty.",
            ELENCO_ARTICOLI_ORDINE: "List of items in the order",
            INFORMAZIONI_NON_DISPONIBILI: "Information not available.",
            TAGLIA: "Size",
            TAGLIE: "Sizes",
            STAGIONE: "Season",
            PREZZO_UNITARIO: "Unit price",
            QUANTITA: "Quantity",
            INDIRIZZO_DI_SPEDIZIONE: "Shipping address",
            NESSUN_INDIRIZZO_SPEDIZIONE: "No shipping address specified for this order.",
            SCEGLI_UN_INDIRIZZO: "Choose an address",
            ORDINE_VERRA_SPEDITO_A: "This order will be shipped to",
            ORDINE_SPEDITO_A: "This order has been shipped to",
            PREZZO_CORRENTE_AGGIORNATO: "Updated current price",
            CONFERMA_RIMOZIONE_ARTICOLO: (sku: string) => <p>Are you sure you want to remove the item <strong>{sku}</strong> from the order?</p>,
            CONFERMA_SVUOTAMENTO_ORDINE: "Are you sure you want to empty the order?",
            INDIRIZZO_ATTUALE: "Current address",
            NUOVO_INDIRIZZO: "Enter a new address",
            SELEZIONA_UN_ALTRO_INDIRIZZO: "Select another address",
            ACQUISTA_ALTRE_TAGLIE: "Purchase other sizes",            
        },
        ITEM: {
            MAGGIORI_INFORMAZIONI: "More information",
            VARIAZIONI_DISPONIBILI: "Available variations",
            NASCONDI_ESAURITE: "Hide out of stock",
            MORE_INFO: {
                BRAND: "Brand",
                COLOR: "Color",
                COMPOSITION: "Composition",
                DESCRIZIONE: "Description",
                HS_CODE: "HS Code",
                SEASON: "Season",
                MADE_IN: "Made in",
                GENDER: "Gender",
                FIRST_CATEGORY: "First category",
                SECOND_CATEGORY: "Second category",
                SCALA_TAGLIE: "Size scale",
                TITOLO: "Title",
                DIMENSIONI: "Dimensions",
                CARRY_OVER: "Carry Over",
            }            
        },
        ACCESS_DENIED: {
            ACCESSO_NEGATO: "Access Denied",
            ACCESSO_NEGATO_DESCRIZIONE: "You do not have permission to access this resource."
        },
        MAINTENANCE: {
            MANUTENZIONE_IN_CORSO: "Maintenance in progress",
            MANUTENZIONE_IN_CORSO_DESCRIZIONE: "We are performing maintenance operations, but we will be back online soon."
        }        
    },
    ITEMS: {
        NO_ITEM_PRESENT: "There are no items in the order.",
        ITEM_NOT_PRESENT: "Item not present in the inventory.",
        ITEMS_ORDER: "Items in this order",
        SKU: "SKU",
        BRAND: "Brand",
        SEASON: "Season",
        TITLE: "Title",
        QT_ROW: "Order Line Quantity",
        TOT_ROW: "Total row cost",
        SIZE: "Size",
        COLOR: "Color",
        QTY: "Quantity",
        PRICE: "Price",
    },
    FILTERS: {
        SELEZIONARE_ALMENO_UN_FILTRO: "Select at least one filter to view results.",
        NESSUN_RISULTATO: "No results found.",
        ERRORE: "An error occurred, please try again."
    },    
    LANGUAGES : {
        DE: "German",
        DK: "Danish",
        ES: "Spanish",
        EU: "European Union",
        FR: "French",
        EN: "English",
        IT: "Italian",
        RU: "Russian",
        SE: "Swedish",
        CN: "Chinese"
    },
    COUNTRIES_CODE: {
        AC: "Ascension Island",
        AD: "Andorra",
        AE: "United Arab Emirates",
        AF: "Afghanistan",
        AG: "Antigua and Barbuda",
        AI: "Anguilla",
        AL: "Albania",
        AM: "Armenia",
        AO: "Angola",
        AQ: "Antarctica",
        AR: "Argentina",
        AS: "American Samoa",
        AT: "Austria",
        AU: "Australia",
        AW: "Aruba",
        AX: "Åland Islands",
        AZ: "Azerbaijan",
        BA: "Bosnia and Herzegovina",
        BB: "Barbados",
        BD: "Bangladesh",
        BE: "Belgium",
        BF: "Burkina Faso",
        BG: "Bulgaria",
        BH: "Bahrain",
        BI: "Burundi",
        BJ: "Benin",
        BL: "Saint Barthélemy",
        BM: "Bermuda",
        BN: "Brunei",
        BO: "Bolivia",
        BQ: "Caribbean Netherlands",
        BR: "Brazil",
        BS: "Bahamas",
        BT: "Bhutan",
        BV: "Bouvet Island",
        BW: "Botswana",
        BY: "Belarus",
        BZ: "Belize",
        CA: "Canada",
        CC: "Cocos (Keeling) Islands",
        CD: "Congo (Kinshasa)",
        CF: "Central African Republic",
        CG: "Congo (Brazzaville)",
        CH: "Switzerland",
        CI: "Côte d'Ivoire",
        CK: "Cook Islands",
        CL: "Chile",
        CM: "Cameroon",
        CN: "China",
        CO: "Colombia",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Cape Verde",
        CW: "Curaçao",
        CX: "Christmas Island",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DE: "Germany",
        DJ: "Djibouti",
        DK: "Denmark",
        DM: "Dominica",
        DO: "Dominican Republic",
        DZ: "Algeria",
        EC: "Ecuador",
        EE: "Estonia",
        EG: "Egypt",
        EH: "Western Sahara",
        ER: "Eritrea",
        ES: "Spain",
        ET: "Ethiopia",
        FI: "Finland",
        FJ: "Fiji",
        FK: "Falkland Islands",
        FM: "Micronesia",
        FO: "Faroe Islands",
        FR: "France",
        GA: "Gabon",
        GB: "United Kingdom",
        GD: "Grenada",
        GE: "Georgia",
        GF: "French Guiana",
        GG: "Guernsey",
        GH: "Ghana",
        GI: "Gibraltar",
        GL: "Greenland",
        GM: "Gambia",
        GN: "Guinea",
        GP: "Guadeloupe",
        GQ: "Equatorial Guinea",
        GR: "Greece",
        GS: "South Georgia and the South Sandwich Islands",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HK: "Hong Kong",
        HM: "Heard Island and McDonald Islands",
        HN: "Honduras",
        HR: "Croatia",
        HT: "Haiti",
        HU: "Hungary",
        ID: "Indonesia",
        IE: "Ireland",
        IL: "Israel",
        IM: "Isle of Man",
        IN: "India",
        IO: "British Indian Ocean Territory",
        IQ: "Iraq",
        IR: "Iran",
        IS: "Iceland",
        IT: "Italy",
        JE: "Jersey",
        JM: "Jamaica",
        JO: "Jordan",
        JP: "Japan",
        KE: "Kenya",
        KG: "Kyrgyzstan",
        KH: "Cambodia",
        KI: "Kiribati",
        KM: "Comoros",
        KN: "Saint Kitts and Nevis",
        KP: "North Korea",
        KR: "South Korea",
        KW: "Kuwait",
        KY: "Cayman Islands",
        KZ: "Kazakhstan",
        LA: "Laos",
        LB: "Lebanon",
        LC: "Saint Lucia",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesotho",
        LT: "Lithuania",
        LU: "Luxembourg",
        LV: "Latvia",
        LY: "Libya",
        MA: "Morocco",
        MC: "Monaco",
        MD: "Moldova",
        ME: "Montenegro",
        MF: "Saint Martin",
        MG: "Madagascar",
        MH: "Marshall Islands",
        MK: "North Macedonia",
        ML: "Mali",
        MM: "Myanmar (Burma)",
        MN: "Mongolia",
        MO: "Macau",
        MP: "Northern Mariana Islands",
        MQ: "Martinique",
        MR: "Mauritania",
        MS: "Montserrat",
        MT: "Malta",
        MU: "Mauritius",
        MV: "Maldives",
        MW: "Malawi",
        MX: "Mexico",
        MY: "Malaysia",
        MZ: "Mozambique",
        NA: "Namibia",
        NC: "New Caledonia",
        NE: "Niger",
        NF: "Norfolk Island",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Netherlands",
        NO: "Norway",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "New Zealand",
        OM: "Oman",
        PA: "Panama",
        PE: "Peru",
        PF: "French Polynesia",
        PG: "Papua New Guinea",
        PH: "Philippines",
        PK: "Pakistan",
        PL: "Poland",
        PM: "Saint Pierre and Miquelon",
        PN: "Pitcairn Islands",
        PR: "Puerto Rico",
        PS: "Palestine",
        PT: "Portugal",
        PW: "Palau",
        PY: "Paraguay",
        QA: "Qatar",
        RE: "Réunion",
        RO: "Romania",
        RS: "Serbia",
        RU: "Russia",
        RW: "Rwanda",
        SA: "Saudi Arabia",
        SB: "Solomon Islands",
        SC: "Seychelles",
        SD: "Sudan",
        SE: "Sweden",
        SG: "Singapore",
        SH: "Saint Helena",
        SI: "Slovenia",
        SJ: "Svalbard and Jan Mayen",
        SK: "Slovakia",
        SL: "Sierra Leone",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somalia",
        SR: "Suriname",
        SS: "South Sudan",
        ST: "São Tomé and Príncipe",
        SV: "El Salvador",
        SX: "Sint Maarten",
        SY: "Syria",
        SZ: "Eswatini",
        TC: "Turks and Caicos Islands",
        TD: "Chad",
        TF: "French Southern Territories",
        TG: "Togo",
        TH: "Thailand",
        TJ: "Tajikistan",
        TK: "Tokelau",
        TL: "Timor-Leste",
        TM: "Turkmenistan",
        TN: "Tunisia",
        TO: "Tonga",
        TR: "Turkey",
        TT: "Trinidad and Tobago",
        TV: "Tuvalu",
        TW: "Taiwan",
        TZ: "Tanzania",
        UA: "Ukraine",
        UG: "Uganda",
        UM: "United States Minor Outlying Islands",
        US: "United States",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VA: "Vatican City",
        VC: "Saint Vincent and the Grenadines",
        VE: "Venezuela",
        VG: "British Virgin Islands",
        VI: "U.S. Virgin Islands",
        VN: "Vietnam",
        VU: "Vanuatu",
        WF: "Wallis and Futuna",
        WS: "Samoa",
        XK: "Kosovo",
        YE: "Yemen",
        YT: "Mayotte",
        ZA: "South Africa",
        ZM: "Zambia",
        ZW: "Zimbabwe",
        UNDEFINED: "Undefined"
    },
    CURRENCY : {
        AED: "United Arab Emirates Dirham",
        AFN: "Afghani",
        ALL: "Lek",
        AMD: "Armenian Dram",
        ANG: "Netherlands Antillean Guilder",
        AOA: "Kwanza",
        ARS: "Argentine Peso",
        AUD: "Australian Dollar",
        AWG: "Aruban Florin",
        AZN: "Azerbaijan Manat",
        BAM: "Convertible Mark",
        BBD: "Barbados Dollar",
        BDT: "Taka",
        BGN: "Bulgarian Lev",
        BHD: "Bahraini Dinar",
        BIF: "Burundi Franc",
        BMD: "Bermudian Dollar",
        BND: "Brunei Dollar",
        BOB: "Boliviano",
        BRL: "Brazilian Real",
        BSD: "Bahamian Dollar",
        BTN: "Ngultrum",
        BWP: "Pula",
        BYN: "Belarusian Ruble",
        BZD: "Belize Dollar",
        CAD: "Canadian Dollar",
        CDF: "Congolese Franc",
        CHF: "Swiss Franc",
        CLP: "Chilean Peso",
        CNY: "Yuan Renminbi",
        COP: "Colombian Peso",
        CRC: "Costa Rican Colon",
        CUC: "Peso Convertible",
        CUP: "Cuban Peso",
        CVE: "Cabo Verde Escudo",
        CZK: "Czech Koruna",
        DJF: "Djibouti Franc",
        DKK: "Danish Krone",
        DOP: "Dominican Peso",
        DZD: "Algerian Dinar",
        EGP: "Egyptian Pound",
        ERN: "Nakfa",
        ETB: "Ethiopian Birr",
        EUR: "Euro",
        FJD: "Fiji Dollar",
        FKP: "Falkland Islands Pound",
        GBP: "Pound Sterling",
        GEL: "Lari",
        GHS: "Ghana Cedi",
        GIP: "Gibraltar Pound",
        GMD: "Dalasi",
        GNF: "Guinea Franc",
        GTQ: "Quetzal",
        GYD: "Guyana Dollar",
        HKD: "Hong Kong Dollar",
        HNL: "Lempira",
        HRK: "Kuna",
        HTG: "Gourde",
        HUF: "Forint",
        IDR: "Rupiah",
        ILS: "New Israeli Sheqel",
        INR: "Indian Rupee",
        IQD: "Iraqi Dinar",
        IRR: "Iranian Rial",
        ISK: "Iceland Krona",
        JMD: "Jamaican Dollar",
        JOD: "Jordanian Dinar",
        JPY: "Yen",
        KES: "Kenyan Shilling",
        KGS: "Som",
        KHR: "Riel",
        KMF: "Comoro Franc",
        KPW: "North Korean Won",
        KRW: "Won",
        KWD: "Kuwaiti Dinar",
        KYD: "Cayman Islands Dollar",
        KZT: "Tenge",
        LAK: "Kip",
        LBP: "Lebanese Pound",
        LKR: "Sri Lanka Rupee",
        LRD: "Liberian Dollar",
        LSL: "Loti",
        LYD: "Libyan Dinar",
        MAD: "Moroccan Dirham",
        MDL: "Moldovan Leu",
        MGA: "Malagasy Ariary",
        MKD: "Denar",
        MMK: "Kyat",
        MNT: "Tugrik",
        MOP: "Pataca",
        MRU: "Ouguiya",
        MUR: "Mauritius Rupee",
        MVR: "Rufiyaa",
        MWK: "Malawi Kwacha",
        MXN: "Mexican Peso",
        MYR: "Malaysian Ringgit",
        MZN: "Mozambique Metical",
        NAD: "Namibia Dollar",
        NGN: "Naira",
        NIO: "Cordoba Oro",
        NOK: "Norwegian Krone",
        NPR: "Nepalese Rupee",
        NZD: "New Zealand Dollar",
        OMR: "Rial Omani",
        PAB: "Balboa",
        PEN: "Sol",
        PGK: "Kina",
        PHP: "Philippine Peso",
        PKR: "Pakistan Rupee",
        PLN: "Zloty",
        PYG: "Guarani",
        QAR: "Qatari Rial",
        RON: "Romanian Leu",
        RSD: "Serbian Dinar",
        RUB: "Russian Ruble",
        RWF: "Rwanda Franc",
        SAR: "Saudi Riyal",
        SBD: "Solomon Islands Dollar",
        SCR: "Seychelles Rupee",
        SDG: "Sudanese Pound",
        SEK: "Swedish Krona",
        SGD: "Singapore Dollar",
        SHP: "Saint Helena Pound",
        SLL: "Leone",
        SOS: "Somali Shilling",
        SRD: "Surinam Dollar",
        SSP: "South Sudanese Pound",
        STN: "Dobra",
        SYP: "Syrian Pound",
        SZL: "Lilangeni",
        THB: "Baht",
        TJS: "Somoni",
        TMT: "Turkmenistan New Manat",
        TND: "Tunisian Dinar",
        TOP: "Pa’anga",
        TRY: "Turkish Lira",
        TTD: "Trinidad and Tobago Dollar",
        TWD: "New Taiwan Dollar",
        TZS: "Tanzanian Shilling",
        UAH: "Hryvnia",
        UGX: "Uganda Shilling",
        USD: "US Dollar",
        UYU: "Peso Uruguayo",
        UZS: "Uzbekistan Sum",
        VES: "Bolívar Soberano",
        VND: "Dong",
        VUV: "Vatu",
        WST: "Tala",
        XAF: "CFA Franc BEAC",
        XCD: "East Caribbean Dollar",
        XOF: "CFA Franc BCEAO",
        XPF: "CFP Franc",
        YER: "Yemeni Rial",
        ZAR: "Rand",
        ZMW: "Zambian Kwacha",
        ZWL: "Zimbabwe Dollar"
    }
}

export default en